<template>
    <div>
        <b-modal
            id="add_vendor"
            ref="modal"
            :title="formtitles"
            size="xl"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            lazy
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ formtitles }}
                    </div>
                </div>
            </template>
        
                <div class="px-3 py-2" :style="`direction:`+lang.dir">
                    <v-row>
                        <v-col cols="12" md="6">
                            <label>{{lang.vendorid}}</label>
                            <b-form-input class="inborder"
                                v-model="vendorid"
                                readonly
                            >{{vendorid}}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>{{lang.vendor_mobile}}</label>
                            <b-form-input class="inborder"
                                v-model="mobile"
                            >{{mobile}}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>{{lang.vendor_name}}</label>
                            <b-form-input class="inborder"
                                v-model="full_name"
                            >{{full_name}}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>{{lang.vendor_vatids}}</label>
                            <b-form-input class="inborder"
                                v-model="vatid"
                            >{{vatid}}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="12">
                            <label>{{lang.vendor_address}}</label>
                            <b-form-input class="inborder"
                                v-model="address"
                            >{{address}}</b-form-input>
                        </v-col>
                    </v-row>
                </div>

            <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='AddVendor()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ submitname }}</b-button>
                    <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                </div>
            </template>
        </b-modal>

        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            formtitle: '',
            vendorid: '',
            full_name: '',
            vatid: '',
            address: '',
            mobile: '',
            SUBMIT_NAME: '',
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        submitname: function(){
            return this.SUBMIT_NAME;
        },
        formtitles: function(){
            return this.formtitle;
        }
    },
    methods: {
        AddVendor(){
            if(this.full_name == ''){
                this.$snotify.error(this.lang.vendor_name_required, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.mobile == ''){
                this.$snotify.error(this.lang.vendor_mobile_required, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('type','addUpdateVendor');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[vendorid]',this.vendorid)
            post.append('data[mobile]',this.mobile)
            post.append('data[full_name]',this.full_name)
            post.append('data[address]',this.address)
            post.append('data[vatid]',this.vatid)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                this.$parent.getVendors();
                document.getElementById('vendorclose').click();
                this.$snotify.error(this.lang.vendor_updated_successufly, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
            })
        }
    },
}
</script>