<template>
    <div>
        <header-portrate style="display:none" />

        <div class="m-2" style="border:1px solid #000;background:lightgoldenrodyellow">
            <posHeader />
            
            <div :style="`direction:`+lang.dir+`;margin:5px;margin-top:47px;margin-left:30px;margin-right:30px;`">
                <div cols="12" style="display:flex;justify-content:center;">
                    <div class="p-2 text-center" style="border:1px solid #000;width:200px;background:black !important;color:#fff !important">{{ lang.vendors }}</div>
                </div>
                <div :style="`direction:`+lang.dir+`;float:`+lang.lalgin+`;`+lang.lalgin+`:10px;margin-`+lang.lalgin+`:2px;`">
                    <button id="multiply-button" :class="`multiply-button-`+lang.lalgin" @click="addVendor()" v-b-modal.add_vendor>
                        <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_vendor}}</div>
                    </button>
                </div>
                <div style="clear:both;margin-bottom:5px;"></div>
                <v-simple-table class="mt-5 mb-10">
                    <thead>
                        <tr>
                            <th :class="`text-center ` + item.class" v-for="(item,index) in headers" :key="index">{{item.text}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in vendorrows" :key="index">
                            <td class="text-center">{{item.vendorid}}</td>
                            <td class="text-center">{{item.full_name}}</td>
                            <td class="text-center">{{item.mobile}}</td>
                            <td class="text-center">{{item.address}}</td>
                            <td class="text-center">{{item.vatid}}</td>
                            <td class="text-center">{{item.invtotal}}</td>
                            <td class="text-center">{{item.vattotal}}</td>
                            <td style="width:80px;background:darkcyan;border: 2px solid #fff !important;">
                                <v-btn style="box-shadow:none;font-size:0.2em;background:darkcyan;width:100%;color:#fff;height:25px !important;margin-left:5px;" @click="addVoucher(item)" v-b-modal.add_vendor_addpayment>{{lang.add_vendor_voucher}}</v-btn>
                            </td>
                            <td style="width:80px;background:green;border: 2px solid #fff !important;">
                                <v-btn style="box-shadow:none;font-size:0.2em;background:green;width:100%;color:#fff;height:25px !important;margin-left:5px;" @click="getStatement(item)" v-b-modal.vendor_ids>{{lang.client_statement}}</v-btn>
                            </td>
                            <td style="width:80px;background:red;border: 2px solid #fff !important;">
                                <v-btn style="box-shadow:none;font-size:0.2em;background:red;width:100%;color:#fff;height:25px !important;margin-left:5px;" @click="doAddinv(item)" v-b-modal.add_addporch>{{lang.add_invoice}}</v-btn>
                            </td>
                            <td style="width:80px;background:blue;border: 2px solid #fff !important;">
                                <v-btn style="box-shadow:none;font-size:0.2em;background:blue;width:100%;color:#fff;height:25px !important;margin-left:5px;" @click="doEdit(item)" v-b-modal.add_vendor>{{lang.update}}</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </div>
        <purchase ref="addBill" />
        <VendorStatement ref="getStatement" />
        <addVendorPayment ref="addVendorPayment" />
        <add-vendro ref="addVend" />
        <Footer style="display:none;" />
    </div>
</template>

<script>
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import addVendorPayment from '@/components/pos-addVendorPayment.vue';
import Purchase from '@/components/pos-Purchase.vue';
import VendorStatement from './pos-vendorStatement.vue';
import AddVendro from '@/components/pos-addVendro.vue';
import Footer from '@/components/Footer.vue';
import posHeader from '@/components/pos-header.vue';
export default {
    components: { posHeader,HeaderPortrate, Purchase,VendorStatement, AddVendro, Footer,addVendorPayment },
    data() {
        return {
            vendorrows: [],
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function(){
            return [
                {text:this.lang.vendorid,class:"backBlack"},
                {text:this.lang.vendor_name,class:"backBlack"},
                {text:this.lang.vendor_mobile,class:"backBlack"},
                {text:this.lang.vendor_address,class:"backBlack"},
                {text:this.lang.vendor_vatids,class:"backBlack"},
                {text:this.lang.invoice_total,class:"backBlack"},
                {text:this.lang.vat_total,class:"backBlack"},
                {text:this.lang.add_vendor_voucher,class:"backBlack"},
                {text:this.lang.statment,class:"backBlack"},
                {text:this.lang.add_invoice,class:"backBlack"},
                {text:this.lang.action,class:"backBlack"},
            ]
        },
        items: function() {
            return {
                    text: this.lang.vendors,
                    disabled: true,
                    to: '/inventory/vendors',
                }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            // console.log("currentMenu" , license);
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
    },
    created() {
        this.getVendors();
    },
    methods: {
        addVoucher(item){
            this.$refs.addVendorPayment.vendorid = item.vendorid
        },
        addVendor(){
            this.$refs.addVend.vendorid = ''
            this.$refs.addVend.full_name = ''
            this.$refs.addVend.vatid = ''
            this.$refs.addVend.address = ''
            this.$refs.addVend.mobile = ''
            this.$refs.addVend.formtitle = this.lang.add_vendor
            this.$refs.addVend.SUBMIT_NAME = this.lang.add
        },
        doEdit(item){
            this.$refs.addVend.vendorid = item.vendorid
            this.$refs.addVend.full_name = item.full_name
            this.$refs.addVend.vatid = item.vatid
            this.$refs.addVend.address = item.address
            this.$refs.addVend.mobile = item.mobile
            this.$refs.addVend.formtitle = this.lang.update_vendor
            this.$refs.addVend.SUBMIT_NAME = this.lang.update
        },
        getStatement(item){
            this.$refs.getStatement.accountn = item;
            this.$refs.getStatement.accountrows = [];

            this.$refs.getStatement.getReport();
        },
        doAddinv(item){
            this.$refs.addBill.vendorid = item.vendorid;
            this.$refs.addBill.vendorCheck();
        },
        getVendors(){
            const post = new FormData();
            post.append('type','getVendors');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki))

            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                this.vendorrows = res.results.data;
            })
        }
    },
}
</script>